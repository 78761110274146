import { auth } from '@/composition/auth'
import { RouteConfig } from 'vue-router'

export default [
  {
    path: '/',
    name: 'Home',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'Home.vue' */
          '@/views/Home.vue'
        )
      ),
    meta: {
      i18n: 'routes.home',
    },
  },
  {
    path: '/our-team',
    name: 'OurTeam',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'OurTeam.vue' */
          '@/views/OurTeam.vue'
        )
      ),
    meta: {
      i18n: 'routes.ourTeam',
    },
  },
  {
    path: '/our-team/:agentKey',
    name: 'AgentProfile',
    props: ({ params: { agentKey } }) => ({
      agentKey:
        typeof agentKey === 'string' ? Number.parseInt(agentKey, 10) : null,
    }),
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'AgentProfile.vue' */
          '@/views/AgentProfile.vue'
        )
      ),
    meta: {
      i18n: 'routes.agentProfile',
    },
  },
  {
    path: '/for-sale',
    name: 'ForSale',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'ForSale.vue' */
          '@/views/ForSale.vue'
        )
      ),
    meta: {
      i18n: 'routes.forSale',
    },
  },
  {
    path: '/rentals',
    name: 'Rentals',
    props: ({ query: { startingDate, endingDate } }) => ({
      startingDate,
      endingDate,
    }),
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'Rentals.vue' */
          '@/views/Rentals.vue'
        )
      ),
    meta: {
      i18n: 'routes.rentals',
    },
  },
  {
    path: '/property/:propertyKey/:title',
    name: 'Property',
    props: ({ params: { propertyKey } }) => ({
      propertyKey: Number.parseInt(propertyKey, 10),
    }),
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'Property.vue' */
          '@/views/Property.vue'
        )
      ),
    meta: {
      i18n: 'routes.property',
    },
    children: [
      {
        path: 'photos/:imageKey?',
        name: 'PropertyPhotoTour',
        props: ({ params: { imageKey }, query: { photoId } }) => ({
          imageKey:
            typeof imageKey === 'string' ? Number.parseInt(imageKey, 10) : null,
          scrollToImageKey:
            typeof photoId === 'string' ? Number.parseInt(photoId, 10) : null,
        }),
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: 'PropertyPhotoTour.vue' */
              '@/views/PropertyPhotoTour.vue'
            )
          ),
        meta: {
          i18n: 'routes.propertyPhotoTour',
        },
      },
    ],
  },
  {
    path: '/mls-search',
    name: 'MlsSearch',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'MlsSearch.vue' */
          '@/views/MlsSearch.vue'
        )
      ),
    meta: {
      i18n: 'routes.mlsSearch',
    },
  },
  {
    path: '/experiences',
    name: 'Experiences',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'Experiences.vue' */
          '@/views/Experiences.vue'
        )
      ),
    meta: {
      i18n: 'routes.experiences',
    },
  },
  {
    path: '/compare-listings',
    name: 'CompareListings',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'CompareListings.vue' */
          '@/views/CompareListings.vue'
        )
      ),
    meta: {
      i18n: 'routes.compareListings',
    },
  },
  {
    path: '/privacy-notice',
    alias: '/aviso-de-privacidad',
    name: 'PrivacyNotice',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'PrivacyNotice.vue' */
          '@/views/PrivacyNotice.vue'
        )
      ),
    meta: {
      i18n: 'routes.privacyNotice',
    },
  },
  {
    path: '/costessa',
    name: 'Costessa',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'Costessa.vue' */
          '@/views/Costessa.vue'
        )
      ),
    meta: {
      i18n: 'routes.costessa',
    },
    children: [
      {
        path: 'photos/:imageKey?',
        name: 'CostessaPhotoTour',
        props: ({ params: { imageKey }, query: { photoId } }) => ({
          imageKey:
            typeof imageKey === 'string' ? Number.parseInt(imageKey, 10) : null,
          scrollToImageKey:
            typeof photoId === 'string' ? Number.parseInt(photoId, 10) : null,
        }),
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: 'CostessaPhotoTour.vue' */
              '@/views/CostessaPhotoTour.vue'
            )
          ),
        meta: {
          i18n: 'routes.CostessaPhotoTour',
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'Login.vue' */
          '@/views/Login.vue'
        )
      ),
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (auth.loggedIn.value) {
        // Redirect to the admin page instead
        next({ name: 'Dashboard' })
      } else {
        // Continue to the login page
        next()
      }
    },
    meta: {
      i18n: 'routes.login',
      layout: 'LayoutCommon',
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter(routeTo, routeFrom, next) {
      auth.logout()
      // Navigate back to login
      next({ name: 'Login' })
    },
    meta: {
      i18n: 'routes.logout',
      authRequired: true,
    },
  },
  {
    path: '/not-found',
    name: 'ErrorNotFound',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'ErrorNotFound.vue' */
          '@/views/ErrorNotFound.vue'
        )
      ),
    meta: {
      i18n: 'routes.errorNotFound',
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    redirect: {
      name: 'PropertyList',
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    redirect: {
      name: 'PropertyList',
    },
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'Dashboard.vue' */
          '@/views/admin/Dashboard.vue'
        )
      ),
    meta: {
      i18n: 'routes.dashboard',
      authRequired: true,
      layout: 'LayoutAdmin',
    },
  },
  {
    path: '/properties',
    name: 'Properties',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'Properties.vue' */
          '@/views/admin/Properties.vue'
        )
      ),
    redirect: {
      name: 'PropertyList',
    },
    children: [
      {
        path: 'list',
        name: 'PropertyList',
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: 'PropertyList.vue' */
              '@/views/admin/PropertyList.vue'
            )
          ),
        meta: {
          i18n: 'routes.propertyList',
          authRequired: true,
          layout: 'LayoutAdmin',
        },
      },
      {
        path: 'create',
        name: 'PropertyCreate',
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: 'PropertyCreate.vue' */
              '@/views/admin/PropertyCreate.vue'
            )
          ),
        meta: {
          i18n: 'routes.propertyCreate',
          authRequired: true,
          layout: 'LayoutAdmin',
        },
      },
      {
        path: 'update/:propertyKey',
        name: 'PropertyUpdate',
        props: ({ params: { propertyKey } }) => ({
          propertyKey:
            typeof propertyKey === 'string'
              ? Number.parseInt(propertyKey, 10)
              : null,
        }),
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: 'PropertyUpdate.vue' */
              '@/views/admin/PropertyUpdate.vue'
            )
          ),
        meta: {
          i18n: 'routes.propertyUpdate',
          authRequired: true,
          layout: 'LayoutAdmin',
        },
      },
    ],
    meta: {
      i18n: 'routes.properties',
      authRequired: true,
      layout: 'LayoutAdmin',
    },
  },
  {
    path: '/exchange-rate/update',
    name: 'ExchangeRateUpdate',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'ExchangeRateUpdate.vue' */
          '@/views/admin/ExchangeRateUpdate.vue'
        )
      ),
    meta: {
      i18n: 'routes.exchangeRateUpdate',
      authRequired: true,
      layout: 'LayoutAdmin',
    },
  },
  {
    path: '/typologies',
    name: 'Typologies',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'Typologies.vue' */
          '@/views/admin/Typologies.vue'
        )
      ),
    meta: {
      i18n: 'routes.typologies',
      authRequired: true,
      layout: 'LayoutAdmin',
    },
  },
  {
    path: '*',
    redirect: { name: 'Home', params: { resource: '' } },
  },
] as RouteConfig[]

//===
// Private helpers
//===

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@/views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@/views/my-view')
//
function lazyLoadView(AsyncView: Promise<any>) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@/views/states/Loading.vue').default,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@/views/states/Timeout.vue').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h: any, { data, children }: any) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}
