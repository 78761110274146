





































































































































































































































































































































































import BrandLogo from '@/components/branding/BrandLogo.vue'
import SiteLanguageLinks from '@/components/SiteLanguageLinks.vue'
import { SiteHeaderItemConfig } from '@/composition/siteHeader'
import { formatPhoneNumber } from '@/utils/phone'
import { currentRoute } from '@/router'
import { title, socials, address, phone, email } from '@/app.config.json'
import { costessaMenu } from '@/utils/costessa'
import { defineComponent, PropType, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'SiteHeader',

  components: {
    BrandLogo,
    SiteLanguageLinks,
  },

  props: {
    items: {
      type: Array as PropType<SiteHeaderItemConfig[]>,
      required: true,
    },
  },

  setup(props) {
    const mainNavItems = props.items.filter((item) => !item.excludeFromMainNav)
    const isMobileMenuOpen = ref(false)
    const isCostessaMenuOpen = ref(false)
    const isCostessaMenuMobileOpen = ref(false)

    watch(
      () => currentRoute.value,
      () => {
        isMobileMenuOpen.value = false
        isCostessaMenuOpen.value = false
        isCostessaMenuMobileOpen.value = false
      }
    )

    function closeMenu(): void {
      if (!isMobileMenuOpen.value) return
      isMobileMenuOpen.value = false
      isCostessaMenuMobileOpen.value = false
    }

    function toggleMenu(): void {
      isMobileMenuOpen.value = !isMobileMenuOpen.value
    }

    function closeCostessaMenu(): void {
      if (!isCostessaMenuOpen.value) return
      isCostessaMenuOpen.value = false
    }

    return {
      mainNavItems,
      title,
      socials,
      address,
      phone,
      formattedPhone: formatPhoneNumber(phone),
      email,
      isMobileMenuOpen,
      closeMenu,
      toggleMenu,
      isCostessaMenuOpen,
      isCostessaMenuMobileOpen,
      costessaMenu,
      closeCostessaMenu,
    }
  },
})
